.page-footer {
  background-color: #fff;
  border-radius: 50px 50px 0px 0px;
  padding: 30px 0;
  &__wrapper {
    @include column-center;
  }
  &__logo {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &-img {
      margin-right: 10px;
    }
    &-text {
      padding: 14px 20px;
      font-family: "Gilroy";
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__hr {
    width: 100%;
    max-width: 150px;
    margin-bottom: 30px;
  }
  &__nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 70px;
    &-link {
      margin: 0 10px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  @media screen and (max-width: 800px) {
    border-radius: 30px 30px 0px 0px;
    &__nav {
      flex-direction: column;
      width: auto;
      align-items: center;
      margin-bottom: 50px;
      &-link {
        margin: 0;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__logo,
    &__hr {
      margin-bottom: 30px;
    }
    &__logo {
      &-text {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}
.copy {
  font-weight: 500;
  color: #949494;
  line-height: 140%;
}
.modal {
  position: fixed;
  top: 90px;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  padding: 8px;
  overflow: auto;
  z-index: -1;
  opacity: 0;
  transition: z-index 0s 0.3s, opacity 0.3s;
  &.open {
    opacity: 1;
    z-index: 10;
    transition: z-index 0s 0s, opacity 0.3s;
  }
  &-wrapper {
    padding: 120px 0;
  }
  &-close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
  &-body {
    margin: auto;
    width: 100%;
    background: #ffffff;
    border-radius: 30px;
    padding: 30px;
    position: relative;
  }
  &-title {
    @include h2-font;
    margin-bottom: 15px;
  }
  &-desc {
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 30px;
  }
  &-form {
    & > * {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__label {
      font-weight: 600;
      line-height: 18px;
      display: block;
      margin-bottom: 15px;
      &--mob {
        display: none;
      }
    }
    &__radio {
      display: flex;
      gap: 10px;
      &-input {
        display: none;
        appearance: none;
      }
      &-label {
        background: #f5f5f5;
        border-radius: 20px;
        padding: 15px 29px;
        border: 1px solid #f5f5f5;
        font-family: "Gilroy";
        font-weight: 800;
        line-height: 18px;
        cursor: pointer;
      }
      &-input:checked + &-label {
        color: var(--main-color);
        border-color: var(--main-color);
      }
      &-input:checked + .modal-form__checkbox-text::before {
        background: var(--main-color);
        border-color: var(--main-color);
      }
    }
    &__inputs {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      &-block {
        width: calc(50% - 15px);
      }
    }
    &__input {
      background: #f5f5f5;
      padding: 15px 19px;
      font-size: 16px;
      line-height: 18px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      outline: none;
      &--phone {
        padding-left: 59px;
      }
    }
    &__submit {
      display: flex;
      align-items: center;
      flex-wrap: wrap-reverse;
      gap: 30px;
    }
    &__checkbox-text {
      font-weight: 500;
      line-height: 140%;
      display: flex;
      align-items: center;
      &::before {
        content: " ";
        width: 15px;
        height: 15px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        margin-right: 10px;
      }
    }
    &__phone {
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 1px;
        height: 18px;
        background-color: rgba(0, 0, 0, 0.15);
        left: 49px;
      }
      &-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
      }
    }
  }
  @media screen and (max-width: 950px) {
    top: 70px;
  }
  @media screen and (max-width: 600px) {
    &-wrapper {
      padding: 30px 0;
    }
    &-close {
      top: 20px;
      right: 20px;
    }
    &-body {
      padding: 20px;
      border-radius: 25px;
    }
    &-form {
      &__radio {
        width: 100%;
        overflow: auto;
        @include scroll;
      }
      &__label--mob {
        margin-bottom: 0px;
        display: block;
      }
      &__inputs {
        flex-direction: column;
        gap: 15px;
        &-block {
          width: 100%;
          max-width: 260px;
          .modal-form__label {
            display: none;
          }
        }
      }
    }
  }
}
.feedback {
  &-body {
    max-width: 608px;
  }
}
.success {
  &-img {
    margin-bottom: 15px;
  }
  &-body {
    max-width: 400px;
  }
}
