.devices {
  &-types {
    @include three-card;
    margin-bottom: 70px;
    justify-content: center;
    &__card {
      @include box;
      padding: 0;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      padding-left: 30px;
      //padding-right: 16px;
      min-width: 310px;
      flex-grow: initial;
      &-body {
        margin-right: 15px;
      }
      &-img,
      picture {
        margin-left: auto;
        display: block;
        transform: translateX(0);
        transition: transform 0.3s;
      }
      &-text {
        color: #000;
      }
      &--nout {
        background: #e6e7ff;
      }
      &--mono {
        background: #fcebff;
      }
      &--pc {
        background: #e8fbff;
      }
      &-arr {
        position: absolute;
        top: calc(50% - 12px);
        right: 16px;
        opacity: 0;
        transition: opacity 0.3s;
      }

      @media screen and (min-width: 1100px) {
        &:hover &-arr {
          opacity: 1;
        }
        &:hover &-img {
          transform: translateX(-34px);
        }
      }
    }
    @media screen and (max-width: 1100px) {
      &__card {
        width: calc(50% - var(--gap) / 2);
        padding-right: 16px;
        &-img {
          margin-left: auto;
          margin-right: 10px;
        }
        &-arr {
          position: static;
          opacity: 1;
        }
      }
    }
    @media screen and (max-width: 850px) {
      margin-top: -22px;
      margin-bottom: 40px;
      &__card {
        border-radius: 25px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    @media screen and (max-width: 650px) {
      &__card {
        width: 100%;
        min-width: unset;
      }
    }
  }
  .hr {
    margin-bottom: 70px;
    width: 100%;
  }
  &-brands {
    @include column-center;
    &--first {
      margin-bottom: 70px;
    }
    &__title {
      max-width: 550px;
    }
    &__cont {
      margin-bottom: 40px;
      width: 100%;
      max-width: 930px;
      &-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--gap);
        margin-bottom: var(--gap);
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: 420px) {
        margin-bottom: 50px;
      }
    }
    &__item {
      background: #ececec;
      border-radius: 30px;
      padding: 8px;
      width: 130px;
      height: 130px;
      @include flex-center;
      &:first-child {
        margin-left: 50px;
      }
      &:nth-child(5) {
        margin-right: 50px;
      }
      &-img {
        max-width: 100%;
        height: auto;
      }
      &--mini:nth-child(n + 12) {
        display: none;
      }
      @media screen and (max-width: 800px) {
        &:first-child {
          margin-left: 0;
        }
        &:nth-child(5) {
          margin-right: 0;
        }
      }
      @media screen and (max-width: 420px) {
        &:nth-child(n + 7) {
          display: none;
        }
        &:nth-child(even) {
          position: relative;
          top: 20px;
        }
      }
    }
    &__text {
      font-weight: 500;
      font-size: 15px;
      line-height: 140%;
      text-align: center;
      max-width: 500px;
      margin-bottom: 25px;
    }
  }
  &-problems {
    width: 100%;
    @include three-card;
    margin-bottom: 40px;
    &__card {
      background: #f5f5f5;
      border-radius: 20px;
      padding: 20px;
      display: flex;
      align-items: center;
      flex-grow: initial;
      &-img {
        margin-right: 20px;
      }
      &-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 20px;
        min-height: 46px;
      }
      &-text {
        font-family: "Gilroy";
        font-weight: 800;
        line-height: 18px;
      }

      &-link {
        margin-left: auto;
        align-self: end;
      }
      &:nth-child(n + 7) {
        display: none;
      }
    }
    .devices-show {
      margin-bottom: 0;
    }
    @media screen and (max-width: 1000px) {
      &__card {
        &-img {
          display: none;
        }
      }
    }
    @media screen and (max-width: 850px) {
      --offset: 20px;
      margin-bottom: 50px;
      &__card {
        width: calc((100% - var(--gap)) / 2);
        align-items: stretch;
        &-body {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        &-title {
          font-size: 15px;
        }
        &-text {
          margin-top: auto;
        }
        &-link {
          margin-left: -24px;
        }
        &:nth-child(even) {
          position: relative;
          top: 20px;
        }
      }
    }
  }
  &-cont {
    @include column-center;
    margin-bottom: 70px;
  }
  &-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    gap: 10px;
  }
  &-tabs {
    @include column-center;
    .devices-show {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 1100px) {
    --gap: 9px;
  }
  @media screen and (max-width: 850px) {
    .hr,
    &-brands--first,
    &-cont {
      margin-bottom: 40px;
    }
  }
}
