.contacts {
  &-title {
    text-align: left;
    max-width: 440px;
  }
  &-cont {
    @include two-card;
    & > * {
      width: calc(50% - var(--gap) / 2);
    }
  }
  &__info {
    padding: 30px;
    border-radius: 30px;
    background-color: #fff;
    & > * {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-district {
      @include h3-font;
    }
    &-text {
      @include p140;
      margin-bottom: 20px;
      max-width: 270px;
    }
    &-links {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
    &--invert {
      background: var(--main-color);
      color: #fff;
      .button-link {
        color: #000;
        background: #fff;
      }
    }
  }

  @media screen and (max-width: 850px) {
    &-cont {
      display: block;
      & > * {
        width: 100%;
      }
    }
    &__info {
      margin-bottom: 9px;
      border-radius: 25px;
      padding: 20px;

      & > * {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: 560px) {
    .button-link {
      flex-grow: 1;
      justify-content: center;
    }
    .button {
      width: 100%;
    }
    .contacts__info-links {
      flex-wrap: wrap-reverse;
    }
  }
}
