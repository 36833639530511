.paid {
  &-wrapper {
    @include column-center;
  }
  &-title {
    max-width: 460px;
  }
  &-items {
    @include three-card;
    width: 100%;
    margin-bottom: 40px;
    &__card {
      @include box;
      display: flex;
      align-items: center;

      &-img {
        margin: -10px -10px -10px auto;
      }
    }
  }
  &-cards {
    display: flex;
    gap: 40px;
  }
  @media screen and (max-width: 900px) {
    &-items {
      --gap: 9px;
      overflow: auto;
      @include fullwidth;
      @include scroll;
      flex-wrap: nowrap;
      margin-bottom: 30px;
      &__card {
        padding: 20px;
        min-width: 250px;
        &-title {
          margin-bottom: 0;
        }
        &-text {
          display: none;
        }
        &-img {
          margin: 0;
          margin-left: auto;
          width: 70px;
          height: 70px;
        }
      }
    }
    &-cards {
      gap: 10px;
      &__item {
        width: 90px;
        height: 90px;
      }
    }
  }
}
