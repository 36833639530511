.devices-main {
  &-title {
    margin-bottom: 15px;
  }
  &-types {
    width: 100%;
    &__card {
      @include box;
      padding-top: 0;
      width: calc((100% - var(--gap) * 2) / 3);
      &-info {
        display: flex;
        align-items: end;
      }
      &-img {
        max-width: 100%;
        height: auto;
      }
      &-link {
        margin-left: auto;
        flex-shrink: 0;
      }
    }
    .devices-types__card-arr {
      display: none;
    }
  }
  @media screen and (max-width: 850px) {
    --gap: 9px;
    &-types {
      margin-top: 0 !important;
      &__card {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: calc(50% - var(--gap) / 2);
        padding-right: 16px;
        &-img {
          width: 100px;
          margin-left: auto;
          order: 2;
          margin-right: 10px;
        }
        &-info {
          order: 3;
        }
        &-link {
          display: none;
        }
      }
      .devices-types__card-arr {
        display: block;
        order: 1;
      }
    }
    &-title {
      margin-bottom: 15px;
    }
  }
  @media screen and (max-width: 650px) {
    &-types__card {
      width: 100%;
    }
  }
}
