.map {
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  &-cont {
    width: 100%;
    height: 100%;
    min-height: 576px;

    border-radius: 30px;
    & > ymaps {
      border-radius: inherit;
      overflow: hidden;
    }
  }
  iframe {
    min-height: inherit;
  }
  &-master {
    &__card {
      position: absolute;
      z-index: -1;
      opacity: 0;
      transform: translateY(100%);
      transition: all 0.3s;

      &.open {
        z-index: 1;
        opacity: 1;
        transform: translateY(0);
      }
      top: 30px;
      left: 30px;
      padding: 30px;
      background: #ffffff;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 300px;
      & > * {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &-photo {
        width: 100px;
        height: auto;
      }
      &-rate {
        display: flex;
        align-items: center;
        &-star {
          margin-right: 8px;
        }
        &-text {
          font-family: "Gilroy";

          font-weight: 800;
          font-size: 15px;
          line-height: 18px;
        }
      }
      &-exp {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
      }

      &-name {
        @include h3-font;
      }
      .button {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 850px) {
    &-cont {
      min-height: 550px;
    }

    border-radius: 25px;
    &-master {
      &__card {
        padding: 20px;
        border-radius: 25px;
        left: 20px;
        top: 20px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 240px;
        &-photo {
          width: 60px;
          height: auto;
          margin-right: 15px;
        }
        &-name,
        &-exp {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  &__district {
    @include box;
    background: #dd1937;
    max-width: 464px;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 30px;
    display: none;
    &.open {
      display: block;
    }
    &-title,
    &-text {
      color: #fff;
    }
    &-images {
      margin-bottom: 15px;
      display: flex;
    }
    &-text {
      margin-bottom: 50px;
    }
    &-photo {
      margin-left: -15px;
      border-radius: 50%;
      border: 1px solid #dd1937;
      &:first-child {
        margin-left: 0;
      }
    }
    @media screen and (max-width: 600px) {
      position: relative;
      top: 0;
      left: 0;
      max-width: 100%;
      margin-bottom: 9px;
    }
  }
}
.close {
  background-color: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  position: absolute;
  top: 30px;
  right: 30px;
  img {
    display: block;
  }
  @media screen and (max-width: 850px) {
    top: 20px;
    right: 20px;
  }
}
