.portfolio {
  overflow: hidden;
  &-title {
    color: #fff;
    text-align: left;
    max-width: 450px;
  }
  &-buttons {
    display: flex;
    margin-bottom: 40px;
    gap: 15px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }
  &-slider {
    overflow: visible;
    &__slide {
      position: relative;
      border-radius: 30px;
      overflow: hidden;
      &-img {
        width: 100%;
        height: auto;
      }
      &-body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 30px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        color: #fff;
      }
      &-title {
        @include h3-font;
        margin-bottom: 15px;
        min-height: 58px;
      }
      &-text {
        @include p140;
      }
    }
  }
  @media screen and (max-width: 850px) {
    --gap: 9px;
    &-buttons {
      margin-bottom: 30px;
      gap: var(--gap);
      @include fullwidth;
    }
    &-slider__slide {
      border-radius: 25px;
      &-body {
        padding: 20px;
      }
    }
  }
}
