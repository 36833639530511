.masters {
  &-wrapper {
    @include column-center;
  }
  &-title {
    margin-bottom: 15px;
    max-width: 560px;
  }
  &-desc {
    @include p140;
    text-align: center;
    color: #949494;
    max-width: 300px;
    margin-bottom: 40px;
  }
  &-items {
    width: 100%;
    max-width: 1060px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--gap);
    margin-bottom: 40px;
  }
  &-item {
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    width: 190px;
    @media screen and (min-width: 1110px) {
      &--open {
        width: 190px * 2 + 10px;
        margin-left: 10px;
        .masters-item {
          &__info {
            transform: translateX(100%);
            opacity: 1;
          }
          &__img {
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    &__img {
      display: block;
      position: relative;
      z-index: 2;
      border-radius: 30px;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
      transition: all 0.3s;
    }
    &__info {
      @include column-center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      padding: 8px;
      position: absolute;
      height: 100%;
      width: calc(100% + 10px);
      max-width: 200px;
      transform: translateX(0);
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 0.3s;
      &-name {
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
      }
      &-exp,
      &-district {
        padding: 7px 10px;
        border-radius: 10px;
        font-weight: 600;
        line-height: 18px;
      }
      &-exp {
        background: linear-gradient(
          91.83deg,
          rgba(152, 0, 255, 0.2) 0%,
          rgba(61, 150, 255, 0.2) 100%
        );
        margin-bottom: 5px;
      }
      &-district {
        background: rgba(204, 0, 255, 0.2);
      }
    }
  }
  @media screen and (max-width: 1110px) {
    &-items {
      --gap: 9px;
      margin-bottom: 30px;
    }
    &-item {
      &__info {
        position: static;
        width: 100%;
        height: auto;
        opacity: 1;
        box-shadow: none;
        background: #f5f5f5;
      }
      &__img {
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 850px) {
    &-items {
      flex-wrap: nowrap;
      overflow: scroll;
      justify-content: flex-start;
      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
      margin-right: -8px;
      padding-right: 8px;
      width: calc(100% + 8px);
    }
    &-item {
      width: 220px;
      flex-shrink: 0;
      &__img {
        width: 100%;
        border-radius: 25px;
      }
      &__info {
        max-width: unset;
        padding: 20px 8px;
        border-radius: 25px;
      }
    }
    &-title {
      margin-bottom: 15px;
    }
  }
}
