@mixin button-font {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
}
@mixin h2-font {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 130%;
  @media screen and (max-width: 850px) {
    font-size: 26px;
  }
}
@mixin h3-font {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  @media screen and (max-width: 850px) {
    font-size: 18px;
    line-height: 22px;
  }
}
@mixin two-card {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  &__card {
    width: calc(50% - var(--gap) / 2);
  }
}
@mixin three-card {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  &__card {
    width: calc((100% - var(--gap) * 2) / 3);
    flex-grow: 1;
  }
}
@mixin box {
  background: #fff;
  padding: var(--gap);
  border-radius: 30px;
  &-title {
    @include h3-font;
    margin-bottom: 15px;
  }
  &-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
    color: #949494;
  }
  @media screen and (max-width: 850px) {
    padding: 20px;
    border-radius: 25px;
  }
}
@mixin column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin p140 {
  font-weight: 500;
  line-height: 140%;
}
@mixin fullwidth {
  margin-left: -8px;
  margin-right: -8px;
  padding-right: 8px;
  padding-left: 8px;
  width: calc(100% + 16px);
}
@mixin scroll {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}
