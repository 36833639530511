.banner {
  padding-top: 80px;
  padding-bottom: 120px;
  &-title {
    max-width: 850px;
    font-size: 64px;
    line-height: 78px;
    //margin-bottom: 40px;
    margin-right: 15px;
  }
  &-button {
    margin-left: auto;
    width: 200px;
    height: 200px;
    font-size: 24px;
    line-height: 29px;
    transform: rotate(5deg);
    flex-shrink: 0;
  }
  &-info {
    @include two-card;
    margin-bottom: 30px;
    &__card {
      @include box;
      position: relative;
      overflow: hidden;

      &-text {
        margin-bottom: 94px;
      }
      &-body {
        margin-right: 15px;
      }
      &--home {
        .banner-info__card-img {
          position: absolute;
          top: 20px;
          left: 340px;
          width: 41.269841%;
        }
      }
      &--service {
        display: flex;
        top: 30px;
        .banner-info__card-img {
          margin: -20px;
          margin-left: auto;
        }
      }
    }
  }
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    max-width: 1160px;
  }
  .wrapper {
    background: url("../img/banner-back.webp") top right no-repeat;
    background-size: auto 100%;
  }
  .target-info__feedback-masters {
    display: none;
  }
  @media screen and (max-width: 1250px) {
    &-title {
      @include h2-font;
    }
    &-button {
      width: 127px;
      height: 127px;
      font-size: 15px;
      line-height: 18px;
    }
    &-info {
      &__card {
        align-items: center;
        &-img {
          //width: 40%;
          height: auto;
          margin: 0;
        }
        &--service {
          display: flex;
          top: 30px;
          .banner-info__card-img {
            margin: 0px;
            margin-left: auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    padding-top: 60px;
    &-header {
      display: block;
      margin-bottom: 30px;
    }
    &-button {
      transform: rotate(0);
      width: auto;
      height: auto;
    }
    &-title {
      margin-bottom: 30px;
    }
    &-info {
      --gap: 9px;
      margin-right: -8px;
      padding-right: 8px;
      flex-wrap: nowrap;
      &::-webkit-scrollbar {
        width: 0;
      }
      &__card {
        border-radius: 25px;
        padding: 20px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &-img {
          display: none;
        }
        &-text {
          margin-bottom: 15px;
        }
        &-button {
          margin-top: auto;
        }
        &--service {
          top: 0;
        }
      }
      overflow-x: auto;
    }
    .wrapper {
      background-position: right top calc(50% - 15px);
      background-size: 200px 200px;
    }
    .target-info__feedback-masters {
      display: flex;
    }
  }
}
