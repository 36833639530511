.target {
  padding-top: 80px;
  &-title {
    max-width: 700px;
    margin-bottom: 40px;
  }
  &-info {
    display: grid;
    gap: var(--gap);
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 2;
    align-items: start;
    &__meta {
      background: #ffffff;
      border-radius: 30px;
      padding: 22px 20px;
      display: flex;
      align-items: center;
      &-img {
        margin-right: 20px;
      }
      &-text {
        @include h3-font;
      }
      &--1 {
        grid-row: 2;
      }
    }
    &__feedback,
    &__metro {
      grid-row: span 2;
      @include box;
      align-self: stretch;
      & > * {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__feedback {
      background-color: var(--main-color);
      color: #fff;
      &-masters {
        display: flex;
        margin-bottom: 15px;
      }
      &-img {
        margin-left: -15px;
        &:first-child {
          margin-left: 0;
        }
      }
      &-text {
        color: #fff;
      }
    }
    &__metro {
      display: flex;
      flex-direction: column;
      &-items {
        display: flex;
        gap: 10px;
      }
      &-link {
        margin-top: auto;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    &-info {
      display: flex;
      flex-wrap: wrap;
      //
      &__meta {
        width: calc(50% - var(--gap) / 2);
      }
      &__feedback,
      &__metro {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 850px) {
    --gap: 9px;
    padding-top: 60px;
    &-title {
      margin-bottom: 30px;
    }
    &-info {
      &__meta {
        padding: 10px;
        border-radius: 25px;
        justify-content: center;
        &-img {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }
      }
      &__feedback {
        margin-bottom: calc(70px - var(--gap));
        .button {
          width: 100%;
        }
        position: relative;
        &::after {
          content: " ";
          display: block;
          position: absolute;
          height: 1px;
          width: 100%;
          left: 0;
          top: calc(100% + 35px);
          background-color: rgba(255, 255, 255, 0.15);
        }
      }
      &__metro {
        @include column-center;
        &-title {
          order: 1;
        }
        &-items {
          order: 2;
        }
        &-text {
          display: none;
        }
        &-link {
          order: 3;
          .link-text {
            font-family: "Gilroy";
            font-weight: 800;
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    &-info {
      &__meta {
        width: 100%;
      }
    }
  }
}
