@import "../helpers/mixins";
* {
  font-size: 15px;
  box-sizing: border-box;
  font-family: "Raleway";
  font-feature-settings: "pnum" on, "lnum" on;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
  padding: 0;
}

a,
span {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
p {
  margin: 0;
}
button {
  border: none;
  appearance: none;
  outline: none;
}
