.quote {
  color: #fff;

  &-cont {
    padding-bottom: 120px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: flex-start;
  }
  &-img {
    margin-right: 40px;
    margin-top: 5px;
  }
  &-text {
    font-weight: 500;
    font-size: 22px;
    line-height: 160%;
  }
  @media screen and (max-width: 700px) {
    &-cont {
      padding-bottom: 70px;
    }
  }
  @media screen and (max-width: 700px) {
    &-text {
      font-size: 16px;
    }
    &-img {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }
}
.section--white + .quote {
  padding-top: 120px;
  @media screen and (max-width: 850px) {
    padding-top: 70px;
  }
}
