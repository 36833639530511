@use "sass:math";
.menu {
  &-cont {
    display: flex;
    gap: var(--gap);
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &-info {
    &__contacts {
      flex-basis: 520px;
      .contacts__info-links {
        display: none;
      }
    }
    &__phone {
      color: var(--main-color);
    }
    &__box {
      border-radius: 30px;
      padding: 30px;
    }
    &__title {
      @include h3-font;
      margin-bottom: 30px;
    }
    &__text {
      @include p140;
    }

    &__messenger {
      flex-basis: 410px;
      background-color: var(--main-color);
      color: #fff;
      & > * {
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__menu {
      background-color: #fff;
      flex-grow: 1;
    }
    &__nav {
      &-link {
        display: block;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &-text {
        text-align: center;
      }
      &-icon {
        display: none;
      }
    }
  }
  .button-link {
    color: #000;
    background-color: #fff;
  }
  .metro-info__text {
    @include p140;
    margin-left: 10px;
  }
  @media screen and (max-width: 1024px) {
    &-info {
      &__messenger {
        display: none;
      }
    }
  }
  @media screen and (max-width: 850px) {
    .meta-bar__item-title {
      color: #949494;
    }
    .wrapper {
      padding: 0;
    }
    &-cont {
      flex-direction: column-reverse;
      padding-top: 32px;
      gap: 0px;
    }
    &-info {
      &__contacts,
      &__menu {
        width: 100%;
      }
      &__nav {
        display: flex;
        flex-wrap: wrap;
        gap: 9px;
        &-link {
          width: calc(50% - 4.5px);
          background: #ffffff;
          border-radius: 20px;
          padding: 13px;
          margin-bottom: 0;
          @include column-center;
        }
        &-icon {
          display: block;
          margin-bottom: 15px;
        }
        &-feedback {
          order: 1;
          width: 100%;
          margin-top: 11px;
          background: #4047ff;
          border-radius: 20px;
          padding: 16px;
          text-align: center;
          color: #fff;
        }
      }
      &__menu {
        background: none;
        border-radius: 0;
        padding: 0;
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        .menu-info__title {
          font-family: "Gilroy";
          color: #fff;
          font-weight: 800;
          font-size: 26px;
          line-height: 130%;
        }
      }
      &__contacts {
        flex-basis: unset;
        .contacts__info-links {
          display: flex;
          gap: 9px;
          margin-bottom: 0;
        }
        .button-link {
          background: #f5f5f5;
          border-radius: 20px;
          &__img {
            margin-right: 0;
          }
        }
        .hr,
        .target-info__metro-items {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 360px) {
    .button-link {
      flex-grow: 1;
      justify-content: center;
    }
  }
}
