// Переменные, миксины, функции
@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/functions";

// CSS-файлы библиотек
@import "vendor/libs";

// Основное
@import "base/fonts";
@import "base/general";

body {
  background: #000000;
}
.decorated {
  background: #4047ff;
  //display: inline-block;
  padding: 0 10px;
  border-radius: 10px;
}
.button {
  background: #4047ff;
  border-radius: 20px;
  padding: 16px 30px;
  @include button-font;
  color: #ffffff;
  cursor: pointer;

  &--white {
    background-color: #fff;
    color: #000000;
  }
  &--round {
    width: 127px;
    height: 127px;
    border-radius: 100px;
  }
}
.wrapper {
  max-width: 1290px + 16px;
  width: 100%;
  padding: 0 8px;
  margin: auto;
  &--column {
    @include column-center;
  }
}
.h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  color: #fff;
  @media screen and (max-width: 850px) {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
  }
}
.h2 {
  @include h2-font;
}
.colored {
  color: var(--main-color);
}
.section {
  padding: 120px 0;
  &-title {
    @include h2-font;
    text-align: center;
    margin-bottom: 40px;
    color: #fff;
    &--desc {
      margin-bottom: 15px;
    }
  }
  &-desc {
    color: #949494;
    margin-bottom: 40px;
    text-align: center;
  }
  &-text {
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 25px;
    display: block;
    text-align: center;
  }
  @media screen and (max-width: 850px) {
    padding: 70px 0;
    &-title,
    &-desc {
      margin-bottom: 30px;
    }
  }
}
.section--white {
  background: #ffffff;
  border-radius: 50px;
  padding: 70px 0;
  .section-title {
    color: #000;
  }
  @media screen and (max-width: 850px) {
    padding: 30px 0;
    border-radius: 30px;
  }
}
.icon-button {
  background: #ffffff;
  border-radius: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-header + .section:not(.target) {
  padding-top: 60px;
}
.page-header + .section--white {
  margin-top: 10px;
  padding-top: 70px;
  @media screen and (max-width: 850px) {
    padding: 30px 0 !important;
    border-radius: 30px;
  }
}
.hr {
  color: var(--hr-color);
  background: var(--hr-color);
  border: none;
  display: block;
  margin: 0;
  height: 1px;
  &--light {
    --hr-color: rgba(0, 0, 0, 0.15);
  }
  &--dark {
    --hr-color: rgba(255, 255, 255, 0.15);
  }
}
.phone {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 110%;
  display: block;
  @media screen and (max-width: 600px) {
    font-size: 36px;
  }
  @media screen and (max-width: 350px) {
    font-size: 28px;
  }
}
.meta-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  &__item {
    &-title {
      font-weight: 600;
      line-height: 18px;
      display: block;
      margin-bottom: 10px;
    }
    &-text {
      font-family: "Gilroy";
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
    }
  }
  @media screen and (max-width: 600px) {
    gap: 20px;
    &__item {
      &-title {
        font-size: 13px;
        line-height: 15px;
      }
      &-text {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
.button-link {
  display: flex;
  align-items: center;
  padding: 13px 15px;
  background: #ececec;
  border-radius: 20px;
  font-weight: 700;
  line-height: 18px;
  &__img {
    margin-right: 10px;
  }
}
.link {
  font-weight: 600;
  color: #000;
  line-height: 18px;
  transition: color 0.3s;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  &-img {
    margin-left: 10px;
  }
  &:hover,
  &--active {
    color: var(--main-color);
  }
}
// БЭМ-блоки
@import "../blocks/modules";
