.advans {
  &-wrapper {
    display: flex;
    gap: var(--gap);
    align-items: flex-start;
  }
  &-header {
    max-width: 295px;
  }
  &-title {
    color: #fff;
    text-align: left;
    margin-bottom: 20px;
  }
  &-desc {
    font-family: "Raleway";
    font-weight: 500;
    line-height: 140%;
    color: #fff;
  }
  &-items {
    @include two-card;
    &__card {
      @include box;
      width: calc(42.708333% - 8px);
      display: flex;
      position: relative;
      min-width: 350px;
      padding-bottom: 84px;
      &:first-child {
        margin-left: auto;
      }
      &-img {
        align-self: flex-end;
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
      &-body {
        max-width: 220px;
      }
    }
  }
  @media screen and (max-width: 1080px) {
    &-wrapper {
      display: block;
    }
    &-items {
      --gap: 9px;
      max-width: unset;
      &__card {
        padding: 20px;
        border-radius: 25px;
        width: calc(50% - var(--gap) / 2);
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &-header {
      max-width: unset;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 740px) {
    &-desc {
      display: none;
    }
    &-title {
      margin-bottom: 0;
    }
    &-items {
      margin-bottom: 20px;

      &__card {
        flex-direction: column-reverse;
        min-width: unset;
        &:nth-child(even) {
          position: relative;
          top: 20px;
        }
        &-img {
          position: static;
          align-self: flex-start;
          margin-bottom: 15px;
          width: 60px;
          height: auto;
        }
        &-title {
          margin-top: auto;
        }
        &-text {
          font-size: 13px;
          line-height: 140%;
        }
      }
    }
  }
}
