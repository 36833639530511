.page-header {
  height: 90px;
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 10;
  &__wrapper {
    display: flex;
    width: 100%;
    max-width: 1318px + 8px + 8px;
    padding: 0 8px;
    height: inherit;
    margin: auto;
    align-items: center;
    & > * {
      background: #ffffff;
      border-radius: 20px;
    }
  }
  &__logo {
    margin-right: auto;
    padding: 14px 15px;
  }
  &__button,
  &__districts,
  &__burger {
    margin-left: 10px;
  }

  &__logo,
  &__burger,
  &__districts {
    display: flex;
    align-items: center;

    font-weight: 800;
    &-text {
      margin-left: 10px;
    }
    * {
      font-family: "Gilroy";
    }
  }
  &__districts {
    padding: 16px 15px;
    &-text {
      margin-right: 15px;
      margin-left: 0;
      display: flex;
      align-items: center;
      &:after {
        content: " ";
        width: 1px;
        margin-left: 15px;
        height: 18px;
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
  &__button {
    color: #4047ff;
  }
  &__burger {
    padding: 13px 15px;
    cursor: pointer;
  }
  @media screen and (max-width: 950px) {
    height: 70px;
    &__burger {
      padding: 13px;
      border-radius: 20px;
      &-text {
        display: none;
      }
    }
    &__districts,
    &__button {
      display: none;
    }
  }
}
