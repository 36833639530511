.districts-main {
  .wrapper {
    background: url("../img/districts-back.webp") top left
      calc(50% + 135px + 200px) no-repeat;
  }
  &__title,
  &__desc {
    color: #fff;
    text-align: left;
  }
  &__title {
    margin-bottom: 15px;
  }
  &__form {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 464px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 850px) {
    &__desc,
    &__form {
      margin-bottom: 30px;
    }
  }
}
.select {
  flex-grow: 1;
  background: #ffffff;
  padding: 16px 30px 16px 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: calc(100% - 114px);
  &:active {
    border-color: red;
  }
}
