.reviews {
  &-wrapper {
    @include column-center;
  }
  &-title {
    color: #fff;
    max-width: 380px;
  }
  &-slider {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
    .swiper {
      margin: 0 15px;
    }
    &__wrapper {
      max-width: 850px;
      align-items: flex-start;
    }
    &__button {
      cursor: pointer;
      &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    &__slide {
      color: #fff;
      padding: 30px;
      border-radius: 30px;
      &-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      &-img {
        margin-right: 15px;
      }
      &-name {
        display: flex;
        align-items: center;
        @include h3-font;
        &:before {
          content: " ";
          width: 1px;
          height: 24px;
          background: rgba(0, 0, 0, 0.15);
          margin-right: 15px;
        }
      }
      &-text {
        font-weight: 500;
        line-height: 150%;
        overflow: hidden;
        transition: height 0.3s;
      }
      &-show {
        display: none;
        margin-top: 20px;
        font-family: "Gilroy";
        font-weight: 800;
        line-height: 18px;
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  &-text {
    color: #fff;
    margin-bottom: 25px;
    max-width: 320px;
    text-align: center;
    @include p140;
  }
  &-source {
    display: flex;
    gap: 10px;
    &__item {
      --side: 84px;
      width: var(--side);
      height: var(--side);
      background-color: #fff;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 850px) {
    &-slider {
      &__wrapper {
        max-width: unset;
      }
      &__slide {
        padding: 20px;
        border-radius: 25px;
        &-img {
          margin-right: 15px;
          &::after {
            margin-left: 15px;
          }
        }
      }
      .swiper {
        margin: 0;
        margin-right: -8px;
        width: calc(100% + 8px);
        padding-right: 8px;
      }
      &__button {
        display: none;
      }
    }
    &-source__item {
      width: 74px;
      height: 74px;
    }
  }
  @media screen and (max-width: 350px) {
    &-source__item {
      width: 50px;
      height: 50px;
    }
  }
}
.google {
  background: #518ef8;
}
.yandex {
  background: #fc3f1d;
}
