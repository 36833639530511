.districts {
  background: url("../img/metro-back.svg") top 50% left no-repeat;
  background-size: 100% auto;
  --offset: 40px;
  &-wrapper {
    @include column-center;
  }
  &-title {
    margin-bottom: 20px;
    max-width: 710px;
  }
  &-desc {
    color: #fff;
    margin-bottom: 40px;
    @include p140;
    max-width: 390px;
    text-align: center;
  }
  &-items {
    @include two-card;
    max-width: 850px;
    margin-bottom: var(--offset);
    &__card {
      @include box;
      position: relative;
      &:nth-child(even) {
        top: var(--offset);
      }
      &-img {
        display: block;
        margin-bottom: 15px;
      }
      &-actions {
        display: flex;
        gap: 10px;
      }
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-radius: 15px;
        padding: 5px;
      }
      &--feedback {
        background-color: var(--main-color);
        color: #fff;
        .districts-items__card {
          &-text {
            color: #fff;
            margin-bottom: 15px;
          }
          &-img {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    --gap: 9px;
    --offset: 20px;
  }
  @media screen and (max-width: 620px) {
    --gap: 9px;
    --offset: 20px;
    .districts-items__card {
      &-text,
      &-actions {
        display: none;
      }
      &-title {
        margin-bottom: 0;
      }
      &--feedback {
        .districts-items__card-img {
          display: block;
        }
      }
    }
  }
}
