.roadmap {
  background: url("../img/roadmap-back.svg") top calc(50% + 130px) left
    no-repeat;
  background-size: 100% auto;
  &-wrapper {
    @include column-center;
  }
  &-title {
    color: #fff;
    max-width: 500px;
  }
  &-items {
    @include two-card;
    max-width: 850px;
    margin-bottom: 80px;
    &__card {
      @include box;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:nth-child(even) {
        top: 40px;
      }
      &-img {
        margin-bottom: 15px;
      }
      &-text {
        margin-bottom: 70px;
      }
      &-step {
        margin-top: auto;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
  &-text {
    color: #fff;
    margin-bottom: 25px;
    max-width: 311px;
    text-align: center;
    font-weight: 500;

    line-height: 140%;
  }
  @media screen and (max-width: 870px) {
    --gap: 9px;
    &-items {
      &__card {
        padding: 20px;
        border-radius: 25px;
      }
    }
  }
  @media screen and (max-width: 650px) {
    &-items__card {
      &-img {
        width: 60px;
        height: 60px;
      }
      &-text {
        display: none;
      }
      &-title {
        margin-bottom: 40px;
      }
      &-step {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}
