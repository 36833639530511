.brands {
  &-title {
    margin-bottom: 15px;
  }
  &-items {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
    margin-bottom: 40px;
  }
  .devices-brands__item {
    &:nth-child(15n + 1),
    &:first-child {
      margin-left: 50px;
    }
    &:nth-child(5) {
      margin-right: 0;
    }
    &:nth-child(15n + 7),
    &:last-child {
      margin-right: 50px;
    }
  }
  @media screen and (max-width: 1270px) {
    .devices-brands__item {
      &:nth-child(15n + 1),
      &:first-child,
      &:nth-child(5),
      &:nth-child(15n + 7),
      &:last-child {
        margin: 0;
      }
    }
  }
  @media screen and (max-width: 850px) {
    --gap: 9px;
    &-items {
      margin-bottom: 50px;
      justify-content: flex-start;
    }
    .devices-brands__item:nth-child(n + 7) {
      display: flex;
    }
    .devices-brands__item {
      width: calc(50% - var(--gap) / 2);
      flex-grow: 1;
      height: 100px;
    }
    // .devices-brands__item:nth-child(odd) {

    //   position: relative;
    //   top: 20px;
    // }
  }
}
