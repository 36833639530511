.about-slider {
  &__title {
    text-align: left;
    max-width: 700px;
    margin-left: 81px;
  }
  &__slider {
    --offset: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-wrapper {
      max-width: 1130px;
      margin: 0 15px;
      overflow: hidden;
      padding-bottom: var(--offset);
    }
    &-slide {
      height: auto;
      &:nth-child(even) {
        position: relative;
        top: var(--offset);
      }
    }
  }
  &__quote {
    max-width: 801px;
  }
  @media screen and (max-width: 850px) {
    &__title {
      margin-left: 0;
    }
    &__slider {
      --offset: 0;
      &-wrapper {
        @include fullwidth;
      }
    }
  }
}
.about-sert {
  &__title {
    max-width: 800px;
  }
  &__items {
    @include two-card;
    margin-bottom: 40px;
  }
  &__text {
    color: #fff;
    max-width: 310px;
  }
  @media screen and (max-width: 850px) {
    &__items {
      --gap: 9px;
      &-item {
        width: calc(50% - var(--gap) / 2);
        height: auto;
      }
    }
  }
}
.about-history {
  background: url("../img/roadmap-back.svg") top calc(50% + 130px) left
    no-repeat;
  background-size: 100% auto;
  &-items {
    @include two-card;
    max-width: 850px;
    --offset: 40px;
    margin-bottom: var(--offset);
    &__card {
      @include box;
      &:nth-child(even) {
        position: relative;
        top: var(--offset);
      }
      &-year {
        background: #4047ff;
        border-radius: 20px;
        padding: 10px 15px;
        display: inline-block;
        width: auto;
        margin-bottom: 30px;
        @include h3-font;
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 850px) {
    --gap: 9px;
    &-items {
      --offset: 20px;
      &__card {
        &-text {
          display: none;
        }
        &-title {
          margin-bottom: 0;
        }
        &-year {
          margin-bottom: 15px;
        }
      }
    }
  }
}
